import { callCep } from "../api";

export const getCep = async (cep) => {
  let address;

  const { data } = await callCep(cep);

  if (data?.error === true) {
    return {
      error: true,
      address: "",
    };
  }

  address = `${data.logradouro}, ${data.bairro}, ${data.localidade} — ${data.uf}`;

  return {
    error: false,
    address,
  };
};
