import React from "react";
import Form from "react-bootstrap/Form";

export const FormField = ({
  hideLabel = false,
  name,
  label,
  error,
  value,
  touched,
  handleChange,
  handleBlur,
  type,
  placeholder,
  bottomChildren,
  fieldAttributes,
  ...rest
}) => (
  <Form.Group className="text-start mb-3" controlId={`form${name}`} {...rest}>
    <Form.Label hidden={hideLabel}>{label}</Form.Label>
    <Form.Control
      type={type || "text"}
      onChange={handleChange}
      onBlur={handleBlur}
      value={value}
      placeholder={placeholder}
      name={name}
      {...fieldAttributes}
    />

    {error && touched ? (
      <p className="text-danger mb-0 text-left">{error}</p>
    ) : null}

    {bottomChildren}
  </Form.Group>
);
