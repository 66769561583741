import {
  cheapestInstallment,
  maxPossibleInstallment,
} from "../../CheckoutBillingTypeUtils";
import { translateBillingType } from "../../billing_types";

export const getBillingTypesOptions = checkout => checkout?.payment_types;

export const billingTypesPerKey = checkout => {
  return getBillingTypesOptions(checkout).reduce((acc, current) => {
    acc[current] = {
      name: translateBillingType(current),
      billing_type: current,
      installments: checkout?.installments,
      interest_rate: checkout?.interest_rate,
    };

    return acc;
  }, {});
};

export const getMaxPossibleInstallment = checkout => {
  const billingTypes = billingTypesPerKey(checkout);
  return maxPossibleInstallment(checkout, billingTypes);
};

export const getCheapestInstallment = checkout => {
  const billingTypes = billingTypesPerKey(checkout);
  return cheapestInstallment(checkout, billingTypes);
};
