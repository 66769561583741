import React from "react";
import { api } from "../api";
import { CheckoutNormalizer } from "../components/utils/CheckoutFormats/CheckoutNormalizer";

export const useCheckout = () => {
  const [loading, setLoading] = React.useState(false);
  const [checkout, setCheckout] = React.useState(null);
  const [countries, setCountries] = React.useState([]);
  const [organization, setOrganization] = React.useState(null);
  const [error, setError] = React.useState(null);
  const checkoutID = () =>
    window.location.pathname.split("/").filter(i => !!i)[0];

  const getCheckout = async () => {
    try {
      setLoading(true);
      const response = await api.get(`checkout/${checkoutID()}`);
      const data = response?.data;
      const checkoutData = data?.checkout;
      const version = checkout?.checkout_payment_types_available?.length
        ? "v2"
        : "v1";
      const formatter = CheckoutNormalizer(version);

      const normalizedData = {
        ...checkoutData,
        product_name: checkoutData?.product?.name || checkoutData?.name,
        billing_types_options: formatter.getBillingTypesOptions(checkoutData),
        billing_types: formatter.billingTypesPerKey(checkoutData),
        max_installment_billing_type:
          formatter.getMaxPossibleInstallment(checkoutData),
        cheapest_installment_billing_type:
          formatter.getCheapestInstallment(checkoutData),
      };

      setLoading(false);
      setCheckout(normalizedData);
      setOrganization(data?.organization);
      setCountries(data?.countries);
    } catch (error) {
      console.log("Error on requesting Checkout API", error);
      setLoading(false);
      setError({ error: error.message });
    }
  };

  return {
    getCheckout,
    checkout,
    organization,
    countries,
    loading,
    error,
  };
};
