import React from "react";
import Image from "react-bootstrap/Image";
import styled from "styled-components";

import { ThemeContext } from "../utils/themeContext";

const Logo = styled(Image)`
  max-width: 300px;
  max-height: 100%;
`;

export const Loading = () => {
  const theme = React.useContext(ThemeContext);

  return (
    <div className="d-flex justify-content-center vw-100 vh-100 align-items-center">
      <div>
        <Logo src={theme.logo} />
        <p className="mt-4">Carregando...</p>
      </div>
    </div>
  );
};

export default Loading;
