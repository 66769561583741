import PropTypes from "prop-types";

export const CheckoutFormCustomFieldsType = {
  errors: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  customFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.node,
      title: PropTypes.node,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.node,
          value: PropTypes.node,
        }),
      ),
    }),
  ),
};
