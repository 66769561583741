import React from "react";
import styled from 'styled-components';

const Text = styled.p`
  font-size: 12px;
  text-align: left;
  margin-bottom: 10px;
`

export const ForeignAlert = ({ location }) => {
  if ( location === null || location?.country_code === 'BR' ) {
    return null;
  }

  return (
    <Text className="text-muted"><em>Está acessando de um outro país ({ location?.country_name }) diferente do Brasil? Para pagamentos de não residentes, lembre-se de escolher o seu país de nacionalidade</em></Text>
  )
};
