import React from "react";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import InputGroup from "react-bootstrap/InputGroup";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { format, parseISO } from "date-fns";
import { formatCurrency } from "@brazilian-utils/brazilian-utils";
import { useGenericAPI } from "../../hooks/useGenericAPI";
import { InlineLoading, AllPaidNotice } from "..";

export const PixForm = ({ payment }) => {
  const { payment: data } = payment;
  const [showCopy, setShowCopy] = React.useState(false);
  const [retry, setRetry] = React.useState(false);

  const installment = React.useMemo(
    () =>
      data.installments_from_gateway.find(i =>
        ["pending", "overdue"].includes(i.status),
      ),
    [data],
  );

  const { getRequest, loading, data: pix, error } = useGenericAPI(`pix/`);

  const allPaid = React.useMemo(
    () =>
      data.installments_from_gateway.filter(i => ["paid"].includes(i.status))
        .length === data.installments_from_gateway.length,
    [data],
  );

  React.useEffect(
    () => {
      if (allPaid) {
        return;
      }

      getRequest(installment?.gateway_id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [installment?.gateway_id, allPaid, retry],
  );

  React.useEffect(() => {
    if (error) {
      setTimeout(() => {
        setRetry(!retry);
      }, 1000);
    }
  }, [error, retry]);

  if (allPaid) {
    return <AllPaidNotice />;
  }

  if (loading || !data || !pix) {
    return <InlineLoading title="Carregando seu PIX, um momento…" />;
  }

  const dueDate = format(parseISO(installment.due_date), "dd/MM/yyyy");

  const copyPix = () => {
    if (!pix) {
      return null;
    }

    setShowCopy(true);
  };

  return (
    <div>
      <Card className="mt-3">
        <Card.Body>
          <Card.Title as="h5">
            Pagar: {installment.description} — (Vencimento: {dueDate})
          </Card.Title>

          <p>
            Acesse o aplicativo do seu banco e faça a leitura do QR Code abaixo
            para efetuar o pagamento de forma rápida e segura.
          </p>

          <Container>
            <Row>
              <Col className="d-flex align-items-center justify-content-center">
                <img
                  className="js-pix-qr-code mb-3"
                  height="240px"
                  width="240px"
                  src={`data:image/jpeg;base64,${pix.encodedImage}`}
                  alt="QR Code Pix"
                ></img>
              </Col>

              <Col className="d-flex align-items-center justify-content-center flex-column">
                <p className="fs-3 text-success">
                  <strong>Valor: R$ {formatCurrency(installment.total)}</strong>
                </p>

                <img
                  src="https://s3-sa-east-1.amazonaws.com/asaas-static/images/invoice/site-seguro.png"
                  alt="Imagem informando que o site é seguro"
                />

                <p className="pt-3 text-">
                  Após a confirmação do pagamento, você receberá um e-mail com o
                  acesso ao produto adquirido em poucos minutos. Você pode
                  fechar esta página caso já tenha efetuado o pagamento.
                </p>
              </Col>
            </Row>
          </Container>

          <h5 className="mt-3">Se preferir, use o PIX Copia e Cola</h5>

          <InputGroup className="mb-2">
            <Form.Control readOnly value={pix.payload} isValid={showCopy} />
            <CopyToClipboard onCopy={copyPix} text={pix.payload}>
              <Button type="button" variant="dark" id="button-addon2">
                {showCopy ? "Código Copiado!" : "Copiar código PIX"}
              </Button>
            </CopyToClipboard>
          </InputGroup>
        </Card.Body>
      </Card>
    </div>
  );
};

PixForm.propTypes = {
  data: PropTypes.object,
};

export default PixForm;
