import React from "react";
import { Redirect, useParams } from "react-router-dom";
import { CheckoutLockedUtil } from "../components/CheckoutLocked/utils/CheckoutLockedUtil";
import CheckoutUtils from "../components/utils/CheckoutUtils";

function PageClearUnlock() {
  const [cleared, setCleared] = React.useState(false);
  const { id, slug } = useParams();

  React.useEffect(() => {
    CheckoutLockedUtil.clear();

    setTimeout(() => {
      setCleared(true);
    }, 100);
  }, [id, slug]);

  if (cleared) {
    return <Redirect to={CheckoutUtils.buildURL(id, slug)} />;
  }

  return null;
}

export default PageClearUnlock;
