import PropTypes from "prop-types";
import styled from "styled-components";
import React, { useCallback } from "react";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import { BsCreditCard } from "react-icons/bs";
import { BILLING_TYPE_OPTIONS } from "../utils/billing_types";

const Option = styled.span`
  width: max-content;
  flex: 1;
  margin-left: 5px;
  justify-content: flex-start;
`;

export const CheckoutFormOptions = ({
  onSelect,
  options = [],
  currentBillingType = {},
}) => {
  const [selected, setSelected] = React.useState(
    currentBillingType?.billing_type,
  );
  const setOption = useCallback(
    option => {
      setSelected(option);
      onSelect(option);
    },
    [onSelect],
  );

  return (
    <Container className="mt-3">
      <h2 className="mb-3 fs-5">Selecione um método para pagar:</h2>

      <div className="d-flex align-items-center justify-content-center">
        {options.includes(BILLING_TYPE_OPTIONS.creditCard) && (
          <Button
            variant={
              selected === BILLING_TYPE_OPTIONS.creditCard
                ? "primary"
                : "secondary"
            }
            onClick={() => setOption("CREDIT_CARD")}
            className="btn-lg"
          >
            <BsCreditCard className="fs-4" style={{ width: 20 }} />
            <Option>
              Cartão <span className="d-none d-md-inline">de Crédito</span>
            </Option>
          </Button>
        )}

        {options.includes(BILLING_TYPE_OPTIONS.pix) && (
          <Button
            variant={
              selected === BILLING_TYPE_OPTIONS.pix ? "primary" : "secondary"
            }
            onClick={() => setOption("PIX")}
            className="ms-3 btn-lg"
          >
            <Image
              src="/checkout/pix-icon.png"
              width={20}
              style={{ width: 20 }}
              alt="PIX Icon Image"
            />
            <Option>PIX</Option>
          </Button>
        )}

        {options.includes(BILLING_TYPE_OPTIONS.boleto) && (
          <Button
            variant={
              selected === BILLING_TYPE_OPTIONS.boleto ? "primary" : "secondary"
            }
            onClick={() => setOption("BOLETO")}
            className="ms-3 btn-lg"
          >
            <Image
              src="/checkout/boleto-icon.png"
              width={20}
              style={{ width: 20 }}
              alt="Boleto Icon Image"
            />
            <Option>Boleto</Option>
          </Button>
        )}
      </div>
    </Container>
  );
};

CheckoutFormOptions.propTypes = {
  onSelect: PropTypes.func,
  options: PropTypes.array,
};
