import React from "react";
import PropTypes from "prop-types";
import Toast from "react-bootstrap/Toast";
import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 30px;
  right: 30px;
`;

export const Notification = ({ title, body, ...rest }) => (
  <Container>
    <Toast {...rest}>
      <Toast.Header>
        <strong className="me-auto">{title}</strong>
      </Toast.Header>
      <Toast.Body>{body}</Toast.Body>
    </Toast>
  </Container>
);

Notification.propTypes = {
  title: PropTypes.node,
  body: PropTypes.node,
};

export default Notification;
