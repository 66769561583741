import React from "react";
import Container from "react-bootstrap/Container";
import { Redirect } from "react-router-dom";

import {
  CheckoutDetails,
  CheckoutForm,
  CheckoutHeader,
  TopBar,
  Loading,
  Whatsapp,
} from "../components";

import { useCheckout } from "../hooks/useCheckout";
import { CheckoutLockedUtil } from "../components/CheckoutLocked/utils/CheckoutLockedUtil";
import { CheckoutFooter } from "../components/CheckoutFooter/CheckoutFooter";

function PageDetails() {
  const { getCheckout, loading, checkout, organization, countries, error } =
    useCheckout();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(async () => await getCheckout(), []);

  if (loading || !checkout) {
    return <Loading />;
  }

  if (error) {
    return <Redirect to="/" />;
  }

  if (!CheckoutLockedUtil.userCanPurchase(checkout)) {
    return <Redirect to={CheckoutLockedUtil.checkoutLockedURL(checkout)} />;
  }

  return (
    <div>
      <Whatsapp url={checkout?.whatsapp_support_line} />
      <TopBar />

      <Container className="text-center">
        <CheckoutHeader checkout={checkout} organization={organization} />
        <CheckoutDetails checkout={checkout} />
        <CheckoutForm checkout={checkout} countries={countries} />
        <CheckoutFooter organization={organization} />
      </Container>
    </div>
  );
}

export default PageDetails;
