import {
  BiCheckCircle,
  BiTime,
  BiErrorAlt,
  BiQuestionMark,
} from "react-icons/bi";

export const colorSuccess = "#009358";
export const colorWarning = "#FF8F00";
export const colorDanger = "red";
export const colorUnknown = "#ccc";

export const parseStatus = str =>
  ({
    pending: "Aguardando pagamento",
    paid: "Paga",
    deleted_or_canceled_by_new_payment: "Cancelada",
    overdue: "Atrasada",
    refunded: "Reembolsada",
  }[str]);

export const parseStatusColor = str => {
  if (!str) {
    return colorUnknown;
  }

  return {
    pending: colorWarning,
    paid: colorSuccess,
    overdue: colorDanger,
    refunded: colorWarning,
  }[str];
};

export const parseStatusIcon = str => {
  if (!str) {
    return BiQuestionMark;
  }

  return {
    pending: BiTime,
    paid: BiCheckCircle,
    overdue: BiErrorAlt,
    refunded: BiCheckCircle,
  }[str];
};
