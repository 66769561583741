const ONE_DAY_IN_MILLISECONDS = 86400000;

export const CheckoutLockedUtil = {
  set: async (token, email) => {
    global.localStorage.setItem(
      "checkoutLocked",
      JSON.stringify({
        token,
        email,
        expiresAt: new Date().getTime() + ONE_DAY_IN_MILLISECONDS,
      }),
    );
  },

  get: () => {
    const checkoutLocked =
      global.localStorage.getItem("checkoutLocked") || false;

    if (!checkoutLocked) {
      return false;
    }

    const { expiresAt } = JSON.parse(checkoutLocked);

    // If expired, still returns null, needs to unlock the checkout again
    if (new Date().getTime() > expiresAt) {
      global.localStorage.removeItem("checkoutLocked");

      return false;
    }

    return JSON.parse(checkoutLocked);
  },

  clear: () => {
    global.localStorage.removeItem("checkoutLocked");
  },

  userCanPurchase: checkoutData => {
    // If there is no checkout data, user can purchase
    if (!checkoutData) {
      return true;
    }

    // Checkout is not locked, user can purchase
    if (!checkoutData?.locked) {
      return true;
    }

    // Checkout is locked, check if user has a valid token
    const checkoutLocked = CheckoutLockedUtil.get();

    // Expired or data is not there
    if (!checkoutLocked) {
      return false;
    }

    // If there's a token and an email, user can purchase (Backend will check for token validity)
    if (!checkoutLocked?.token || !checkoutLocked?.email) {
      return false;
    }

    return true;
  },
  checkoutLockedURL: checkoutData =>
    `/${checkoutData?.id}/${checkoutData?.slug}/locked/`,
};
