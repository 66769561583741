import React from "react";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import InputGroup from "react-bootstrap/InputGroup";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { format, parseISO } from "date-fns";
import { formatCurrency } from "@brazilian-utils/brazilian-utils";
import { useGenericAPI } from "../../hooks/useGenericAPI";
import { AllPaidNotice, InlineLoading } from "..";

export const BoletoForm = ({ payment }) => {
  const { payment: data } = payment;
  const [showCopy, setShowCopy] = React.useState(false);
  const installment = React.useMemo(
    () =>
      data.installments_from_gateway.find(i =>
        ["pending", "overdue"].includes(i.status),
      ),
    [data],
  );

  const {
    getRequest,
    loading,
    data: boletoLine,
    error,
  } = useGenericAPI(`boletos/`);

  const allPaid = React.useMemo(
    () =>
      data.installments_from_gateway.filter(i => ["paid"].includes(i.status))
        .length === data.installments_from_gateway.length,
    [data],
  );

  React.useEffect(
    () => {
      if (allPaid) {
        return;
      }

      getRequest(installment.gateway_id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [installment?.gateway_id, allPaid],
  );

  if (allPaid) {
    return <AllPaidNotice />;
  }

  if (loading || !data) {
    return <InlineLoading />;
  }

  const dueDate = format(parseISO(installment.due_date), "dd/MM/yyyy");

  const copyLine = () => {
    if (!boletoLine) {
      return null;
    }

    setShowCopy(true);
  };

  return (
    <div>
      <Card className="mt-3">
        <Card.Body>
          <Card.Title as="h5">
            Pagar: {installment.description} — (Vencimento: {dueDate})
          </Card.Title>
          <p>
            Clique no botão abaixo para acessar o Boleto. Será aberta uma nova
            ABA no seu navegador.
          </p>

          <Container>
            <Row>
              <Col className="d-flex align-items-center justify-content-center">
                <Button
                  as="a"
                  size="lg"
                  title="Clique para baixar o boleto para dispositivo"
                  className="m-3"
                  onClick={() =>
                    window.open(`${installment.boleto_url}?download=true`)
                  }
                >
                  Baixar Boleto
                </Button>

                <Button
                  as="a"
                  size="lg"
                  title="Clique para imprimir o boleto"
                  onClick={() => window.open(installment.boleto_url)}
                >
                  Imprimir Boleto
                </Button>
              </Col>

              <Col className="d-flex align-items-center justify-content-center flex-column">
                <p className="fs-3 text-success">
                  <strong>Valor: R$ {formatCurrency(installment.total)}</strong>
                </p>

                <img
                  src="https://s3-sa-east-1.amazonaws.com/asaas-static/images/invoice/site-seguro.png"
                  alt="Imagem informando que o site é seguro"
                />

                <p>
                  Após a compensação do pagamento (que pode levar entre 24 e 48
                  horas), você receberá um e-mail com o seu acesso para o
                  produto adquirido. Você pode fechar esta página caso já tenha
                  efetuado o pagamento ou gerado o boleto.
                </p>
              </Col>
            </Row>
          </Container>

          {!error && boletoLine && (
            <div>
              <h5 className="mt-3">Se preferir, copie a linha digitável</h5>

              <InputGroup className="mb-2">
                <Form.Control
                  readOnly
                  value={boletoLine.identificationField}
                  isValid={showCopy}
                />
                <CopyToClipboard
                  onCopy={copyLine}
                  text={boletoLine.identificationField}
                >
                  <Button
                    title="Clique para copia para o clipboard a linha digitável do boleto"
                    type="button"
                    variant="dark"
                    id="button-addon2"
                  >
                    {showCopy ? "Linha Copiada!" : "Copiar linha digitável"}
                  </Button>
                </CopyToClipboard>
              </InputGroup>
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

BoletoForm.propTypes = {
  data: PropTypes.object,
};

export default BoletoForm;
