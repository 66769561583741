import { withFormik } from "formik";
import { PaymentRecoverFormFields } from "./PaymentRecoverFormFields";
import { api } from "../../api";
import { isValidCPF, isValidCNPJ } from "@brazilian-utils/brazilian-utils";
import { withRouter } from "react-router-dom";

const defaultErrorMessage =
  "O request não pode ser feito, por favor verifique se todos os campos realmente estão preenchidos corretamente. Se o problema persistir, entre em contato conosco.";

export const PaymentRecoverForm = withRouter(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: () => ({
      doc_number: "",
      email: "",
      global_error: "",
    }),

    // Custom sync validation
    validate: values => {
      const errors = {};
      const baseErrorDocNumber =
        "Documento inválido, por favor digite um CPF ou CNPJ válido. Use somente números. Se você for de outro país, selecione outro país na lista acima.";

      if (!values.doc_number) {
        errors.doc_number = baseErrorDocNumber;
      } else if (
        !isValidCPF(values.doc_number) &&
        !isValidCNPJ(values.doc_number)
      ) {
        errors.doc_number = baseErrorDocNumber;
      }

      if (!values.email) {
        errors.email =
          "Seu e-mail é indispensável para prosseguir. Favor digitar um e-mail válido.";
      }

      return errors;
    },

    handleSubmit: async (
      values,
      { setSubmitting, setFieldError, props: { onResults } },
    ) => {
      setSubmitting(true);

      try {
        const params = `doc_number=${values.doc_number}&email=${values.email}`;
        const request = await api.get(`/payments?${params}`, values);

        if (request?.data?.payments?.length) {
          setSubmitting(true);
          global.Sentry.captureMessage("Request Payments Success", {
            rollbarData: request.data,
          });

          onResults(`/meus-pagamentos?${params}`);
        } else {
          setSubmitting(false);

          setFieldError(
            "global_error",
            "Não foram encontados pagamentos ativos para esta combinação de E-mail e Documento.",
          );
        }
      } catch (error) {
        setSubmitting(false);
        if (error.response) {
          setFieldError(
            "global_error",
            "Cliente não encontrado para esta combinação de e-mail e CPF",
          );
        } else {
          console.log(error);
          setFieldError("global_error", defaultErrorMessage);
          global.Sentry.captureMessage("Global Error", error.toJSON());
        }
      }
    },

    displayName: "PaymentRecoverForm",
  })(PaymentRecoverFormFields),
);
