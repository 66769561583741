import React from "react";
import { api } from "../api";

export const usePayment = () => {
  const [loading, setLoading] = React.useState(false);
  const [payment, setPayment] = React.useState(null);
  const [error, setError] = React.useState(null);

  const getPayment = async (id) => {
    try {
      setLoading(true);
      const response = await api.get(`payments/${id}`);
      setLoading(false);
      setPayment(response.data);
    } catch (error) {
      setLoading(false);
      setError({ error: error.message });
    }
  };

  return {
    getPayment,
    payment,
    loading,
    error,
  };
};
