import React from "react";
import Container from "react-bootstrap/Container";
import { Redirect } from "react-router-dom";

import Strategies from "../components/CheckoutLocked/";
import { CheckoutHeader, TopBar, Loading, Whatsapp } from "../components";
import { useCheckout } from "../hooks/useCheckout";

function PageLocked() {
  const { getCheckout, loading, checkout, organization, error } = useCheckout();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(async () => await getCheckout(), []);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Redirect to="/" />;
  }

  if (!checkout) {
    return <Loading />;
  }

  // If not locked, get out of here
  if (checkout && !checkout.locked) {
    return <Redirect to={`/${checkout?.id}/${checkout?.slug}`} />;
  }

  const fields = checkout.locked_fields;
  const FormComponent = fields?.locked_type
    ? Strategies[`${fields?.locked_type}Form`]
    : null;

  if (!fields) {
    return null;
  }

  return (
    <div>
      <Whatsapp url={checkout.whatsapp_support_line} />
      <TopBar />

      <Container className="text-center">
        <CheckoutHeader checkout={checkout} organization={organization} />

        {FormComponent && (
          <FormComponent
            instruction={fields?.instruction}
            checkout={checkout}
          />
        )}
      </Container>
    </div>
  );
}

export default PageLocked;
