const DEFAULT_PARAMS = {
  colors: {
    primary: "#04547d",
  },
  logo: "/themes/ibft/logo.png",
  topBarIcon: "/themes/ibft/icon.png",
  title: "Checkout — Instituto Brasileiro de Formação de Terapeutas",
  contentAccessURL: "https://ibft.com.br/",
  termsURL: "https://ibft.com.br/termos-de-uso",
  privacyPolicyURL: "https://ibft.com.br/politica-privacidade/",
};

const themes = {
  ibft: DEFAULT_PARAMS,
  eudiamond: {
    ...DEFAULT_PARAMS,
    colors: {
      primary: "#000000",
    },
    logo: "/themes/eudiamond/logo.png",
    topBarIcon: "/themes/eudiamond/icon.png",
    title: "Checkout — EUDIAMOND",
    contentAccessURL: "https://www.eudiamond.com.br/",
  },
  cbtrg: {
    ...DEFAULT_PARAMS,
    colors: {
      primary: "#339568",
    },
    logo: "/themes/cbtrg/logo.png",
    topBarIcon: "/themes/cbtrg/icon.png",
    title: "Checkout — EUDIAMOND",
    contentAccessURL: "https://www.cbtrg.com.br/",
  },
};

export default themes;
