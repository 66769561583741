import React from "react";
import { api } from "../api";

export const useGenericAPI = baseURL => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);

  const getRequest = async url => {
    try {
      setError(null);
      setLoading(true);
      const response = await api.get(baseURL + url);
      setData(response.data);
    } catch (error) {
      setError({ error: error.message });
    } finally {
      setLoading(false);
    }
  };

  return {
    getRequest,
    data,
    loading,
    error,
  };
};
