// External
import React from "react";
import Alert from "react-bootstrap/Alert";
import Image from "react-bootstrap/Image";
import styled from "styled-components";

import { ThemeContext } from "../utils/themeContext";

const TopBarAlert = styled(Alert)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.primary};
  color: white;
  border: none;
  border-radius: 0;

  p {
    margin-bottom: 0;
    margin-left: 10px;
    font-size: 0.8rem;
  }
`;

export const TopBar = () => {
  const theme = React.useContext(ThemeContext);

  return (
    <TopBarAlert variant="primary" theme={theme}>
      <Image
        src={theme.topBarIcon}
        width={35}
        alt="Ícone que representa o checkout"
      />
      <p>{process.env.REACT_APP_APP_TOPBAR_TITLE}</p>
    </TopBarAlert>
  );
};

TopBar.propTypes = {};
