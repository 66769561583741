import React from "react";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import { Loading, TopBar } from "../components";

import { usePaymentRecover } from "../hooks/usePaymentRecover";
import { useQuery } from "../hooks/useQuery";
import { translateBillingType } from "../components/utils/billing_types";
import {
  parseStatus,
  parseStatusColor,
} from "../components/utils/payment_statuses";
import { formatCurrency } from "@brazilian-utils/brazilian-utils";

function PagePayments() {
  const query = useQuery();
  const { getData, loading, data, error } = usePaymentRecover();

  React.useEffect(
    () => getData(query.get("doc_number"), query.get("email")),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (loading) {
    return <Loading />;
  }

  const payments = data?.payments || [];

  return (
    <div>
      <TopBar />

      <Container className="text-center">
        {data?.customer?.name && (
          <>
            <h1>Olá, {data?.customer?.name}! </h1>
            <p>
              Veja abaixo os paramentos encontrados para este CPF e E-mail de
              compra:
            </p>
          </>
        )}
        {!!error && <h1>Oops, houve um erro.</h1>}

        <Table striped bordered hover className="mt-5">
          {!error && (
            <thead>
              <tr>
                <th>Produto</th>
                <th>Estado</th>
                <th>Tipo do pagamento</th>
                <th>Parcelamento</th>
                <th>Valor</th>
                <th>Detalhe</th>
              </tr>
            </thead>
          )}
          <tbody>
            {error && (
              <tr key="error">
                <td colSpan={6}>
                  Não conseguimos retornar seus pagamentos no momento. É
                  provável que a combinação do seu e-mail + CPF não esteja igual
                  entre a plataforma de pagamento e a plataforma de cursos.{" "}
                  <strong>Favor contactar o suporte.</strong>
                </td>
              </tr>
            )}
            {!error && !payments.length && (
              <tr key="no_payments">
                <td colSpan={6}>
                  Não existem pagamentos disponíveis para mostrar no momento.
                </td>
              </tr>
            )}
            {!error &&
              !!payments.length &&
              payments.map(({ payment }) => (
                <tr key={payment?.gateway_id}>
                  <td>{payment?.checkout?.name}</td>
                  <td style={{ color: parseStatusColor(payment?.status) }}>
                    {parseStatus(payment?.status)}
                  </td>
                  <td>{translateBillingType(payment?.billing_type)}</td>
                  <td>Em {payment?.installment_count}x</td>
                  <td>R$ {formatCurrency(parseFloat(payment.total))}</td>
                  <td>
                    <a href={`/payments/${payment.gateway_id}`}>Ver Compra</a>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Container>
    </div>
  );
}

export default PagePayments;
