import React from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export const ConfirmModal = ({
  show = false,
  text = "",
  onConfirm,
  onClose,
  fullscreen = false,
  ...rest
}) => (
  <Modal show={show} onHide={onClose} {...rest} fullscreen={fullscreen}>
    <Modal.Header closeButton>
      <Modal.Title>Atenção!</Modal.Title>
    </Modal.Header>
    <Modal.Body>{text}</Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={onClose}>
        Voltar
      </Button>
      <Button variant="primary" onClick={onConfirm}>
        Continuar
      </Button>
    </Modal.Footer>
  </Modal>
);

ConfirmModal.propTypes = {
  show: PropTypes.bool,
  text: PropTypes.node,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  fullscreen: PropTypes.node,
};
