import axios from "axios";

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "https://checkout.ibft.app/api/v1/",
  timeout: 70000,
  headers: {
    "Content-Type": "application/json",
  },
});

const CEPS = [];

const errorReturn = {
  data: {
    error: true,
    address: "",
  },
};

export const callCep = cep => {
  return new Promise(async (res, rej) => {
    if (CEPS[cep]) {
      return res(CEPS[cep]);
    }

    try {
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);

      if (response) {
        CEPS[cep] = response;
      }

      // erro is the name of viaCEP object, not error
      if (response.data?.erro === true) {
        CEPS[cep] = errorReturn;

        return res(errorReturn);
      }

      return res(response);
    } catch (error) {
      CEPS[cep] = errorReturn;

      return res(errorReturn);
    }
  });
};
