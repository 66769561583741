import { withFormik } from "formik";
import { CheckoutFormFields } from "./CheckoutFormFields";
import { api } from "../../api";
import { isValidCPF, isValidCNPJ } from "@brazilian-utils/brazilian-utils";
import { VisitorDataUtil } from "../utils/visitor_data";
import { CheckoutLockedUtil } from "../CheckoutLocked/utils/CheckoutLockedUtil";
import { CREDIT_CARD_BILLING_TYPE } from "../utils/CheckoutBillingTypeUtils";

const defaultErrorMessage =
  "O request não pode ser feito, por favor verifique se todos os campos realmente estão preenchidos corretamente. Se o problema persistir, entre em contato conosco.";
const BRASIL = "BR";

export const CheckoutForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ checkout }) => ({
    name: "",
    utm_params: {},
    doc_number: "",
    phone_number: "",
    country: BRASIL,
    payment_custom_field_answers: [],
    global_error: "",
    checkout_id: checkout?.id,
    locked: checkout?.locked,
    billing_type: checkout?.billing_types_options.includes(
      CREDIT_CARD_BILLING_TYPE,
    )
      ? CREDIT_CARD_BILLING_TYPE
      : checkout?.billing_types_options?.[0],
    email: (() => {
      const checkoutLocked = CheckoutLockedUtil.get() ?? {};

      return checkoutLocked?.email ?? "";
    })(),
    email_confirmation: (() => {
      const checkoutLocked = CheckoutLockedUtil.get() ?? {};

      return checkoutLocked?.email ?? "";
    })(),
    installment_count: (() => {
      const hasCreditCard = checkout?.billing_types_options.includes(
        CREDIT_CARD_BILLING_TYPE,
      );

      if (hasCreditCard) {
        return checkout?.billing_types[CREDIT_CARD_BILLING_TYPE]
          ?.installments[0]?.installment;
      }

      return checkout?.billing_types[checkout?.billing_types_options[0]]
        ?.installments[0]?.installment;
    })(),
    checkout_unlock: (() => {
      const checkoutLocked = CheckoutLockedUtil.get();

      if (!checkoutLocked?.token) {
        return null;
      }

      return {
        data: {
          token: checkoutLocked?.token,
        },
      };
    })(),
  }),

  // Custom sync validation
  validate: (values, props) => {
    try {
      const customFieldsAvailable = props?.checkout?.custom_fields_available;
      const errors = {};
      const defaultGringoError =
        "Para pagamentos com moedas estrangeiras, a única opção disponível é o cartão de crédito sem parcelamento.";
      const baseErrorDocNumber =
        "Documento inválido, por favor digite um CPF ou CNPJ válido. Use somente números. Se você for de outro país, selecione outro país na lista acima.";

      if (!values.name) {
        errors.name = "Preencha seu nome completo.";
      }

      if (!values?.installment_count || values?.installment_count <= 0) {
        errors.installment_count =
          "Selecione em quantas parcelas você quer pagar.";
      }

      if (values?.country !== BRASIL && values?.installment_count > 1) {
        errors.installment_count = defaultGringoError;
      }

      if (!values.billing_type) {
        errors.billing_type =
          "Você deve escolher entre pagar com cartão de crédito ou BOLETO/PIX. Clique em um dos botões acima.";
      }

      if (values?.country !== BRASIL && values?.billing_type === "BOLETO") {
        errors.billing_type = defaultGringoError;
      }

      // Validate custom fields
      if (customFieldsAvailable?.length) {
        customFieldsAvailable?.forEach((field, i) => {
          if (!field?.required) {
            return;
          }

          const fieldValue = values?.payment_custom_field_answers?.[i]?.answer
            ?.toString()
            ?.trim();

          if (!fieldValue) {
            if (!errors.payment_custom_field_answers?.length) {
              errors.payment_custom_field_answers = [];
            }

            errors.payment_custom_field_answers[i] =
              "Campo obrigatório, favor preencher.";
          }
        });
      }

      if (values.country === BRASIL) {
        if (!values.doc_number) {
          errors.doc_number = baseErrorDocNumber;
        } else if (
          !isValidCPF(values.doc_number) &&
          !isValidCNPJ(values.doc_number)
        ) {
          errors.doc_number = baseErrorDocNumber;
        }
      }

      if (!values.phone_number) {
        if (values.country === BRASIL) {
          errors.phone_number =
            "Favor preencher seu número de celular apenas com números começando com o DDD.";
        } else {
          errors.phone_number =
            "Favor preencher seu número de celular com o código do país e apenas números.";
        }
      }

      if (!values.email) {
        errors.email =
          "Seu e-mail é indispensável para prosseguir. Favor digitar um e-mail válido.";
      }

      if (
        !values.email_confirmation ||
        values.email !== values.email_confirmation
      ) {
        errors.email_confirmation =
          "Preencha com o mesmo valor do e-mail já digitado. Verifique se digitou corretamente ambos.";
      }

      return errors;
    } catch (error) {
      console.log("error", error);
    }
  },

  handleSubmit: async (values, { setSubmitting, setFieldError }) => {
    setSubmitting(true);

    // Audit Data for Payment Gateways using VisitorAPI
    const visitor_data = VisitorDataUtil.get();

    // UTM Parameters - Only send if they exist
    const utm_params = Object.keys(values.utm_params)?.length
      ? values.utm_params
      : null;

    try {
      const request = await api.post("/checkout", {
        checkout: {
          ...values,
        },
        visitor_data,
        utm_params,
      });

      if (request.data?.checkout_url) {
        setSubmitting(true);
        global.Sentry.captureMessage("Checkout Success", {
          rollbarData: request.data,
        });
        window.location.href = `${request.data.checkout_url}?billing_type=${values.billing_type}&reference_id=${values.checkout_id}`;
      } else {
        setSubmitting(false);

        setFieldError(
          "global_error",
          "Checkout não conseguiu concluir o processo completo, tente enviar novamente.",
        );
      }
    } catch (error) {
      setSubmitting(false);
      if (error.response) {
        if (error.response.status === 422) {
          const data = error.response.data.errors;

          if (data) {
            Object.keys(data).map(errorKey =>
              setFieldError(errorKey, data[errorKey].join(", ")),
            );
          }

          if (error.response.error) {
            setFieldError("global_error", error.response.error);
          }
        } else {
          setFieldError("global_error", defaultErrorMessage);
          global.Sentry.captureMessage("Global Error", error.toJSON());
        }
      } else {
        setFieldError("global_error", defaultErrorMessage);
        global.Sentry.captureMessage("Global Error", error.toJSON());
      }
    }
  },

  displayName: "CheckoutForm",
})(CheckoutFormFields);
