import {
  cheapestInstallment,
  maxPossibleInstallment,
} from "../../CheckoutBillingTypeUtils";
import { translateBillingType } from "../../billing_types";

export const getBillingTypesOptions = checkout =>
  checkout?.checkout_payment_types_available.map(cpta => cpta?.billing_type);

// Structure:
// [
//   {
//     billing_type: "CREDIT_CARD",
//     installments: []
//   }
//  ]
export const billingTypesPerKey = checkout => {
  return checkout?.checkout_payment_types_available.reduce((acc, current) => {
    acc[current.billing_type] = {
      billing_type: current.billing_type,
      name: translateBillingType(current.billing_type),
      ...current,
    };

    return acc;
  }, {});
};

export const getMaxPossibleInstallment = checkout => {
  const billingTypes = billingTypesPerKey(checkout);
  return maxPossibleInstallment(checkout, billingTypes);
};

export const getCheapestInstallment = checkout => {
  const billingTypes = billingTypesPerKey(checkout);
  return cheapestInstallment(checkout, billingTypes);
};
