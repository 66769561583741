import React, { useCallback, useMemo } from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { CheckoutFormCustomFieldsType } from "../utils/CheckoutFormCustomFieldsType";
import { FloatingLabel } from "react-bootstrap";
import { FormField } from "./FormField";
import { FieldArray } from "formik";

export const CheckoutFormCustomFields = ({ customFields, errors, values }) => {
  const buildCustomField = useCallback(
    (customField, values, arrayHelpers, index, errors) => {
      const { title, id, options } = customField;
      const fieldName = `payment_custom_field_answers[${index}]`;
      const errorField = errors?.payment_custom_field_answers?.[index];
      const value = values?.payment_custom_field_answers?.[index];

      const onChange = ev => {
        const obj = {
          custom_field_id: id,
          answer: ev.target.value,
        };

        if (value?.custom_field_id.toString() === id.toString()) {
          arrayHelpers.replace(index, obj);
        } else {
          arrayHelpers.insert(index, obj);
        }
      };

      if (options?.length) {
        return (
          <div key={fieldName}>
            <FloatingLabel controlId={fieldName} label={title}>
              <Form.Select
                defaultValue={value?.answer || ""}
                onBlur={onChange}
                onChange={onChange}
                aria-label={title}
                className="mb-3"
              >
                <option value="">Selecione uma opção</option>
                {options?.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.value}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>

            {errorField ? (
              <p className="text-danger text-start">{errorField}</p>
            ) : null}
          </div>
        );
      } else {
        return (
          <FormField
            key={fieldName}
            type="text"
            label={title}
            name={fieldName}
            defaultValue={value?.answer}
            error={errorField}
            touched
            handleBlur={onChange}
            handleChange={onChange}
          />
        );
      }
    },
    [],
  );

  const fields = useMemo(
    () => (
      <FieldArray
        name="payment_custom_field_answers"
        render={arrayHelpers => (
          <>
            {customFields.map((customField, index) =>
              buildCustomField(
                customField,
                values,
                arrayHelpers,
                index,
                errors,
              ),
            )}
          </>
        )}
      />
    ),
    [buildCustomField, customFields, values, errors],
  );

  if (!customFields?.length) {
    return null;
  }

  return (
    <Container className="mt-3">
      <Row className="justify-content-md-center">
        <Col md={9} lg={7}>
          <Card body p={2}>
            {fields}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

CheckoutFormCustomFields.propTypes = CheckoutFormCustomFieldsType;
