import PropTypes from "prop-types";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

export const InlineLoading = ({ title = "Carregando...", ...rest }) => (
  <div
    className="d-flex justify-content-center align-items-center flex-column text-center p-3 mt-4"
    {...rest}
  >
    <AiOutlineLoading3Quarters className="icon-spin" />
    <p className="mt-4">{title}</p>
  </div>
);

InlineLoading.propTypes = {
  title: PropTypes.node,
};

export default InlineLoading;
