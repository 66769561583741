import React from "react";
import Alert from "react-bootstrap/Alert";

export const AllPaidNotice = () => (
  <div>
    <Alert variant="info" className="mt-3">
      Não há parcelas a serem pagas para este pagamento. Caso precise de ajuda
      com acesso ao produto/serviço comprado,{" "}
      <a
        href="https://www.ibft.com.br/"
        title="clique aqui para entrar em contato com nosso suporte"
        target="_blank"
        rel="noreferrer"
      >
        clique aqui para entrar em contato com nosso suporte
      </a>
      .
    </Alert>
  </div>
);

export default AllPaidNotice;
