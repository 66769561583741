import React from "react";
import { api } from "../api";

export const usePaymentRecover = () => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState({ customer: null, payments: [] });
  const [error, setError] = React.useState(null);

  const getData = async (doc_number = "", email = "") => {
    try {
      setLoading(true);
      const response = await api.get(
        `payments/?doc_number=${doc_number}&email=${email}`,
      );
      setLoading(false);
      setData(response.data);
    } catch (error) {
      setLoading(false);
      setError({ error: error.response.data });
    }
  };

  return {
    getData,
    data,
    loading,
    error,
  };
};
