import React from "react";
import { CheckoutPropType } from "../utils/CheckoutPropType";

export const CheckoutHeader = ({ checkout }) => (
  <div>
    <h1 className="text-uppercase text-muted">{checkout?.name}</h1>
    <hr />
  </div>
);

CheckoutHeader.propTypes = {
  checkout: CheckoutPropType,
};
