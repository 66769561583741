import React from "react";
import Container from "react-bootstrap/Container";
import { useHistory } from "react-router-dom";
import { TopBar } from "../components";
import { PaymentRecoverForm } from "../components/PaymentRecoverForm/PaymentRecoverForm";
import { GiMoneyStack } from "react-icons/gi";

function PagePaymentRecover() {
  const history = useHistory();

  function setData(url) {
    history.push(encodeURI(url));
  }

  return (
    <div>
      <TopBar />

      <Container className="text-center">
        <h1>
          Segunda Via de seus pagamentos <GiMoneyStack />
        </h1>
        <p>
          Digite abaixo o número documento e o <strong>E-mail</strong> informado
          na hora de sua compra para visualizar todos os seus pagamentos.
        </p>

        <PaymentRecoverForm onResults={setData} />
      </Container>
    </div>
  );
}

export default PagePaymentRecover;
