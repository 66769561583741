import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ThemeContext } from "../utils/themeContext";
import { BiCheckShield } from "react-icons/bi";
const colorSuccess = "#009358";

export const CheckoutFooter = ({ organization }) => {
  const theme = React.useContext(ThemeContext);

  return (
    <div>
      <Container>
        <Row className="justify-content-md-center"></Row>
      </Container>

      <Col className="text-success text-center m-3 fs-6 lh-1">
        <BiCheckShield fontSize={40} color={colorSuccess} /> Compra 100% Segura.
      </Col>

      <p className="fs-6 m-0 text-center">
        Ao continuar, você concorda com os{" "}
        <a href={theme.termsURL} target="_blank" rel="noreferrer">
          termos de uso
        </a>{" "}
        e{" "}
        <a href={theme.privacyPolicyURL} target="_blank" rel="noreferrer">
          políticas de privacidade
        </a>
      </p>

      <p className="fs-6">
        Depois do pagamento, fique atento ao seu e-mail para receber os dados de
        acesso ao produto (verifique também a caixa de SPAM).
        <br />
        {theme.footerText}
      </p>

      <p>
        {organization?.name} © {new Date().getFullYear()} - Todos os direitos
        reservados
      </p>
    </div>
  );
};
