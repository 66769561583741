import React from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { BiCheckCircle } from "react-icons/bi";
import { useParams } from "react-router";
import { Redirect } from "react-router";

import { Loading, TopBar, Whatsapp } from "../components";

import { usePayment } from "../hooks/usePayment";
import { BILLING_TYPES } from "../components/utils/billing_types";

const colorSuccess = "#009358";

export const PagePaymentSuccess = () => {
  const { id } = useParams();
  const { getPayment, loading, payment, error } = usePayment(id);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => getPayment(id), [id]);

  if (loading || !payment) {
    return <Loading />;
  }

  if (error) {
    return <Redirect to="/" />;
  }

  const { payment: paymentData } = payment;
  const { checkout } = paymentData;
  const status = paymentData.status;

  if (status !== "paid") {
    return <Redirect to={`/payments/${id}`} />;
  }

  return (
    <div>
      <Whatsapp url={checkout?.whatsapp_support_line} />
      <TopBar />

      <Container>
        <Card>
          <Card.Body>
            <Card.Title as="h5">Detalhes do seu pagamento</Card.Title>
            <Card.Text>
              Produto: <strong>{checkout?.name}</strong>
              <br />
              Você escolheu:{" "}
              <strong>{BILLING_TYPES[paymentData.billing_type]}</strong> em{" "}
              {paymentData.installment_count}x
              <br />
              Número do pedido: #{paymentData.invoice_number}
            </Card.Text>
          </Card.Body>
        </Card>

        <Card className="mt-3 p-3 text-center">
          <Card.Body>
            <h2 className="text-success">
              <BiCheckCircle
                style={{ fill: colorSuccess, marginRight: "5px" }}
              />{" "}
              Pagamento Confirmado!
            </h2>

            <p className="p-1 my-3 fs-5 w-75 mx-auto">
              Nos próximos minutos você receberá um e-mail com o seu acesso para
              o produto adquirido.
              <br />
              <br />
              Qualquer dúvida você pode entrar no site abaixo e receber todo
              suporte necessário.
            </p>

            <Button
              variant="success"
              as="a"
              href="https://ibft.com.br/"
              size="lg"
            >
              Entrar na área do aluno
            </Button>

            <br />

            <a
              title="Clique para ir para a tela de faturas pagas"
              href={`/payments/${id}`}
              className="mt-3 d-block"
            >
              Visualizar as parcelas pagas
            </a>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default PagePaymentSuccess;
