import VisitorAPI from "visitorapi";

const ONE_DAY_IN_MILLISECONDS = 86400000;

const requestAndSaveData = () => {
  VisitorAPI(process.env.REACT_APP_VISITOR_DATA_KEY, data => {
    global.localStorage.setItem("visitorData", JSON.stringify(data));
    global.localStorage.setItem("visitorDataExpiration", new Date().getTime());
  });
};

export const VisitorDataUtil = {
  set: async () => {
    console.log("Initial visitor data request sent");

    const visitorDataExpiration = global.localStorage.getItem(
      "visitorDataExpiration",
    );

    const visitorData = global.localStorage.getItem("visitorData");

    if (!visitorDataExpiration || !visitorData) {
      return requestAndSaveData();
    }

    const now = new Date().getTime();
    const hasExpired =
      visitorDataExpiration &&
      now - visitorDataExpiration > ONE_DAY_IN_MILLISECONDS;

    if (hasExpired || !visitorData) {
      global.localStorage.removeItem("visitorData");
      global.localStorage.removeItem("visitorDataExpiration");

      requestAndSaveData();
    }
  },

  get: () => {
    const visitorData = global.localStorage.getItem("visitorData") || null;

    return JSON.parse(visitorData);
  },
};
