export const CREDIT_CARD_BILLING_TYPE = "CREDIT_CARD";

export const maxPossibleInstallment = (checkout, billingTypes) => {
  return Object.keys(billingTypes).reduce(
    (acc, current) => {
      const { installments } = billingTypes[current];
      const maxInstallment = installments[installments.length - 1];

      return maxInstallment.installment > acc.installments[0].installment
        ? { ...billingTypes[current], installments: [maxInstallment] }
        : acc;
    },
    { installments: [{ installment: 0 }] },
  );
};

export const cheapestInstallment = (checkout, billingTypes) => {
  return Object.keys(billingTypes).reduce(
    (acc, current) => {
      const { installments } = billingTypes[current];
      const cheapestInstallment = installments[0];

      return cheapestInstallment.installment_amount <
        acc.installments[0].installment_amount
        ? { ...billingTypes[current], installments: [cheapestInstallment] }
        : acc;
    },
    { installments: [{ installment_amount: Infinity }] },
  );
};
